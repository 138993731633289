<template>
	<Breadcrumbs></Breadcrumbs>
	<MDBCard class="m-3" :class="{ 'd-none':(addForm || editForm)}">
    <MDBCardBody>
			<transition name="fade">
				<PageLoading v-if="PageLoading"></PageLoading>
			</transition>
			<transition name="fade">
				<AxiosLoading v-if="loading"></AxiosLoading>
			</transition>
			<MDBRow>
				<MDBCol class="mb-2 text-start" md="2">
					<MDBSelect v-model:options="language.items" :label="$t('COMMON.LANGUAGE')" v-model:selected="lang"></MDBSelect>
				</MDBCol>
				<MDBCol class="mb-2 text-end">
					<MDBBtn color="info" @click="addForm = true">{{ $t("TECHNOLOGYCATEGORY.ADD-ITEM") }}</MDBBtn>  
				</MDBCol>
				<MDBRow>
					<MDBTable
						class="table datatable-table"
						responsive
					>
						<thead>
							<tr>
								<th width="50">#</th>
								<th>{{ $t("COMMON.MAINCATEGORY")}}</th>
								<th>{{ $t("COMMON.SUBCATEGORY")}}</th>
								<th>{{ $t("COMMON.TECHNOLOGYCATEGORY")}}</th>
								<th width="50">{{ $t("COMMON.EDIT")}}</th>
								<th width="50">{{ $t("COMMON.DELETE")}}</th>
							</tr>
						</thead>
						<tbody>
							<template v-for="(item ,k) in Chapter" :key="k">
								<tr scope="row">
									<td></td>
									<td>{{ item.name[lang].name }}</td>
									<td></td>
									<td></td>
									<td>
										<MDBBtn color="info" @click="editUUID = item.uuid" class="px-3 py-2">
											<MDBIcon icon="edit" iconStyle="fas" class="text-white"/>
										</MDBBtn>
									</td>
									<td>
										<MDBBtn color="danger" @click="DeleteAction(item.uuid)" class="px-3 py-2">
											<MDBIcon icon="trash-alt" iconStyle="fas" class="text-white"/>
										</MDBBtn>
										<!-- <a @click="editUUID = item.uuid;" data-bs-toggle="tooltip" :title="$t('TECHNOLOGYCATEGORY.EDIT-ITEM')" href="javascript:void(0)" class="me-2">
											<MDBIcon icon="edit" iconStyle="fas" class="text-primary"/>
										</a> -->
										<!-- <a @click="DeleteAction(item.uuid)" data-bs-toggle="tooltip" :title="$t('TECHNOLOGYCATEGORY.DELETE-ITEM')" href="javascript:void(0)" class="me-2">
											<MDBIcon icon="trash-alt" iconStyle="fas" class="text-danger"/>
										</a> -->
									</td>
								</tr>
								<template v-for="(sub, i) in item.sub" :key="i">
									<tr scope="row">
										<td></td>
										<td></td>
										<td>{{ sub.name[lang].name }}</td>
										<td></td>
										<td>
											<MDBBtn color="info" @click="editUUID = sub.uuid" class="px-3 py-2">
												<MDBIcon icon="edit" iconStyle="fas" class="text-white"/>
											</MDBBtn>
										</td>
										<td>
											<MDBBtn color="danger" @click="DeleteAction(sub.uuid)" class="px-3 py-2">
												<MDBIcon icon="trash-alt" iconStyle="fas" class="text-white"/>
											</MDBBtn>
											<!-- <a @click="editUUID = sub.uuid;" data-bs-toggle="tooltip" :title="$t('TECHNOLOGYCATEGORY.EDIT-ITEM')" href="javascript:void(0)" class="me-2">
												<MDBIcon icon="edit" iconStyle="fas" class="text-primary"/>
											</a>
											<a @click="DeleteAction(sub.uuid)" data-bs-toggle="tooltip" :title="$t('TECHNOLOGYCATEGORY.DELETE-ITEM')" href="javascript:void(0)" class="me-2">
												<MDBIcon icon="trash-alt" iconStyle="fas" class="text-danger"/>
											</a> -->
										</td>
									</tr>
									<template v-for="(technology, j) in sub.sub" :key="j">
										<tr scope="row">
											<td></td>
											<td></td>
											<td></td>
											<td>{{ technology.name[lang].name }}</td>
											<td>
												<MDBBtn color="info" @click="editUUID = technology.uuid" class="px-3 py-2">
													<MDBIcon icon="edit" iconStyle="fas" class="text-white"/>
												</MDBBtn>
											</td>
											<td>
												<MDBBtn color="danger" @click="DeleteAction(technology.uuid)" class="px-3 py-2">
													<MDBIcon icon="trash-alt" iconStyle="fas" class="text-white"/>
												</MDBBtn>
												<!-- <a @click="editUUID = technology.uuid;" data-bs-toggle="tooltip" :title="$t('TECHNOLOGYCATEGORY.EDIT-ITEM')" href="javascript:void(0)" class="me-2">
													<MDBIcon icon="edit" iconStyle="fas" class="text-primary"/>
												</a>
												<a @click="DeleteAction(technology.uuid)" data-bs-toggle="tooltip" :title="$t('TECHNOLOGYCATEGORY.DELETE-ITEM')" href="javascript:void(0)" class="me-2">
													<MDBIcon icon="trash-alt" iconStyle="fas" class="text-danger"/>
												</a> -->
											</td>
										</tr>
									</template>
								</template>
							</template>
						</tbody>
						<tfoot>
							<tr></tr>
						</tfoot>
					</MDBTable>
				</MDBRow>
			</MDBRow>
			
		</MDBCardBody>
  </MDBCard>
  <TechnologyCategoryForm v-model="addForm" action="insert" :data="Chapter"></TechnologyCategoryForm>
  <TechnologyCategoryForm v-model="editForm" action="update" v-model:uuid="editUUID" :data="Chapter"></TechnologyCategoryForm>
</template>

<style scope>
	.category {
		list-style: none;
	}
</style>

<script>
import { MDBCard, MDBCardBody, MDBIcon, MDBSelect, MDBRow, MDBCol, MDBBtn, MDBTable } from 'mdb-vue-ui-kit';
import Breadcrumbs from '@/components/Breadcrumbs';
import PageLoading from '@/components/Elements/PageLoading';
import AxiosLoading from '@/components/Elements/AxiosLoading';
import { ref, reactive } from "vue";
import i18nService from "@/core/services/i18n.service.js";
import TechnologyCategoryForm from '@/components/Form/Technology/TechnologyCategoryForm';
import ApiService from "@/core/services/api.service";
import CommonService from "@/core/services/common.service";

export default {
  name: "TechnologyCategory",
  components: {
		MDBCard,
		Breadcrumbs,
		MDBCardBody,
		PageLoading,
		MDBIcon,
		MDBSelect,
		MDBRow,
		MDBCol,
		MDBBtn,
		TechnologyCategoryForm,
		AxiosLoading,
		MDBTable
  },
  setup() {
		const PageLoading = ref(true);
		const lang = ref(i18nService.getActiveLanguage());
		const language = reactive({
      items: i18nService.languages.map(item => { item.selected = (item.lang == i18nService.getActiveLanguage()); return item;}),
      selected: ""
    });
    const addForm = ref(false);
    const editForm = ref(false);
    const editUUID = ref("");
    const Chapter = ref({});
    const loading = ref(false);

    const GetTable = function(show = true) {
      return new Promise(resolve => {
        loading.value = show;
        ApiService.query(
          "/Admin/v1/TechnologyCategory",
          {
            params: {}
          }
        ).then(response => {
          if (response.status == 200) {
						Chapter.value = response.data.data;
						loading.value = false;
            resolve(response);
          }
        });
      });
    };

    const DeleteAction = function(uuid) {
      CommonService.DeleteSwal().then(result => {
        if (result.isConfirmed) {
					loading.value = true;
          ApiService.delete("/Admin/v1/TechnologyCategory/" + uuid).then(
            response => {
              if (response.status == 200) {
                CommonService.AxiosHandle(response);
                loading.value = false;
                GetTable();
              }
            }
          );
        }
      });
    };

		return {
			PageLoading,
			loading,
			lang,
			language,
			addForm,
			editForm,
			editUUID,
			Chapter,
			GetTable,
			DeleteAction
		};
  },
  mounted() {
    Promise.all([
			this.GetTable(false)
    ]).then(() => {
      this.PageLoading = false;
    });
  },
  provide () {
    return {
      GetTable: this.GetTable
    }
  }
};
</script>