<template>
  <MDBCard class="m-3 form" :class="{ 'd-none':!Model }">
    <MDBCardBody>
      <form @submit="Submit">
        <MDBCardHeader v-if="(action == 'insert')">{{ $t("TECHNOLOGYCATEGORY.ADD-ITEM") }}</MDBCardHeader>
        <MDBCardHeader v-else-if="(action == 'update')">{{ $t("TECHNOLOGYCATEGORY.EDIT-ITEM") }}</MDBCardHeader>
        <MDBCardText class="pt-3">
          <MDBRow class="g-3">
            <MDBCol md="6">
              <MDBRow class="g-3">
                <template v-for="(name, lang) in Form.name.value" :key="lang">
                  <div>
                    <label class="form-label text-dark">{{ ($t(`LANG.${lang}`)+$t(`COMMON.NAME`)) }}</label>
                    <input type="text" class="form-control" :placeholder="($t(`LANG.${lang}`)+$t(`COMMON.NAME`))" v-model="Form.name.value[lang].name">
                    <span class="text-danger">{{ errors[`name.${lang}.name`] }}</span>
                  </div>
                </template>
                <MDBCol md="12">
                  <div>
                    <label class="form-label text-dark">{{ $t(`COMMON.SORT`) }}</label>
                    <input type="number" class="form-control" :placeholder="$t(`COMMON.SORT`)" v-model="Form.sort.value">
                    <span class="text-danger">{{ sortError }}</span>
                  </div>
                </MDBCol>
                <MDBCol md="12" v-if="data.length > 0">
                  <div>
                    <label class="form-label text-dark" >{{ $t(`COMMON.MAINCATEGORY`) }}</label>
                    <select class="form-select" v-model="mainCategory">
                      <option value="" selected>{{ $t(`COMMON.MAINCATEGORY`) }}</option>
                      <template v-for="(item, index) in data" :key="index">
                        <option :value="item.uuid">{{ item.name[lang].name }}</option>  
                      </template>
                    </select>
                    <span class="text-danger">{{ parentError }}</span>
                  </div>
                </MDBCol>
                <MDBCol md="12" v-if="mainCategory">
                  <div>
                    <label class="form-label text-dark" >{{ $t(`COMMON.SUBCATEGORY`) }}</label>
                    <select class="form-select" v-model="subCategory">
                      <option value="" selected>{{ $t(`COMMON.SUBCATEGORY`) }}</option>
                      <template v-for="(item, index) in data.map(item => { if(item.uuid == mainCategory) {return item.sub} }).filter(x => !!x).pop()" :key="index">
                        <option :value="item.uuid">{{ item.name[lang].name }}</option>  
                      </template>
                    </select>
                    <span class="text-danger">{{ parentError }}</span>
                  </div>
                </MDBCol>
                <div class="row mt-3">
                  <label class="form-label text-dark" >關鍵字</label>
                  <template v-for="(item,key) in Form.seo.value" :key="key">
                    <div class="col-md-4">
                      <label>{{$t(`LANG.${key}`)}}</label><br>
                      <template v-for="(value,name) in item" :key="name">
                        {{ $t(`SEO.${name}`) }} :<input type="text" class="form-control mb-1" :placeholder="$t(`SEO.${name}`)" v-model="Form.seo.value[key][name]">
                      </template>
                    </div>
                  </template>
                </div>
              </MDBRow>
            </MDBCol>
          </MDBRow>
        </MDBCardText>
        <MDBBtn v-if="(action == 'insert')" color="primary" type="submit" :disabled="SubmitLoading">
          {{ $t("COMMON.INSERT") }}
          <span class="spinner-border Loading" :class="{ 'd-none':!SubmitLoading }" role="status" >
            <span class="visually-hidden">Loading...</span>
          </span>
        </MDBBtn>
        <MDBBtn v-else-if="(action == 'update')" color="primary" type="submit" :disabled="SubmitLoading">
          {{ $t("COMMON.UPDATE") }}
          <span class="spinner-border Loading" :class="{ 'd-none':!SubmitLoading }" role="status" >
            <span class="visually-hidden">Loading...</span>
          </span>
        </MDBBtn>
        <MDBBtn type="button" @click="Close()">{{ $t("COMMON.CANCEL") }}</MDBBtn>
      </form>
    </MDBCardBody>
  </MDBCard>
</template>

<script>
import { MDBRow, MDBCard, MDBCardHeader, MDBCardBody, MDBCardText, MDBBtn, MDBCol } from 'mdb-vue-ui-kit';
import ApiService from "@/core/services/api.service";
import CommonService from "@/core/services/common.service";
import { computed, ref, inject } from "vue";
import { useForm, useField } from 'vee-validate';
import * as yup from 'yup';
import { useI18n } from 'vue-i18n';
import i18nService from "@/core/services/i18n.service";

export default {
  name: "TechnologyCategoryForm",
  components: {
    MDBRow,
    MDBCard,
    MDBCardHeader,
    MDBCardBody,
    MDBCardText,
    MDBBtn,
    MDBCol
  },
  props: {
    action: {
      type: String,
      default: ""
    },
    modelValue: {
      type: Boolean,
      default: false
    },
    uuid: {
      type: String,
      default: ""
    },
    data: {

    }
  },
  setup(props, { emit }) {
    const Model = computed({
      get: () => props.modelValue,
      set: val => emit("update:modelValue", val)
    });
    const FormUUid = computed({
      get: () => props.uuid,
      set: val => emit("update:uuid", val),
    });

    const SubmitLoading = ref(false);
    const i18n = useI18n();
    const GetTable = inject("GetTable");
    const technologyCategory = ref("");
    const lang = i18nService.getActiveLanguage();
    var mutilpleLangsNmaeValue = {};
    const mutilpleLangsNmae = {};
    for(let k in i18nService.languages) {
      mutilpleLangsNmae[i18nService.languages[k].lang] = yup.object(({ name: yup.string().required().label((i18n.t(`LANG.${i18nService.languages[k].lang}`)+i18n.t(`COMMON.NAME`)))}));
      mutilpleLangsNmaeValue[i18nService.languages[k].lang] = { name: ""} ;
    }
    const basicSchema = {
      name: yup.object().shape(mutilpleLangsNmae).label(i18n.t("COMMON.NAME")),
      sort: yup.number().required().label(i18n.t("COMMON.SORT")),
      parent: yup.string().label(i18n.t("COMMON.UUID")),
      seo: yup.object().label("關鍵字"),
    };
    const FormSchema = yup.object(basicSchema);
    const { handleSubmit, resetForm, errors } = useForm({
      validationSchema: FormSchema,
      initialValues: {
        name: {...mutilpleLangsNmaeValue},
        mainCategory: "",
        subCategory: "",
        parent: "",
        sort: 0,
        seo: {
          cn: {
            title:'',
            keyword:'',
            content:'',
          },
          en: {
            title:'',
            keyword:'',
            content:'',
          },
          tw: {
            title:'',
            keyword:'',
            content:'',
          },
        }
      }
    });

    const { value: name, errorMessage: nameError } = useField('name');
    const { value: sort, errorMessage: sortError } = useField('sort');
    const { value: parent, errorMessage: parentError } = useField('parent');
    const { value: mainCategory } = useField('mainCategory');
    const { value: subCategory } = useField('subCategory');
    const { value: seo, errorMessage: seoError } = useField('seo');
    for(let k in i18nService.languages) {
      useField(`name.${i18nService.languages[k].lang}.name`);
    }
    
    let Submit;

    if(props.action == "insert") {
      Submit = handleSubmit(values => {
        SubmitLoading.value = true;
        ApiService.post("/Admin/v1/TechnologyCategory", values).then(response => {
          if (response.status == 200) {
            CommonService.AxiosHandle(response);
            resetForm();
            Model.value = false;
            SubmitLoading.value = false;
            GetTable();
          }
        });
      });
    }else{
      Submit = handleSubmit(values => {
        SubmitLoading.value = true;
        ApiService.update("/Admin/v1/TechnologyCategory",props.uuid ,values).then(response => {
          if (response.status == 200) {
            CommonService.AxiosHandle(response);
            resetForm();
            Model.value = false;
            SubmitLoading.value = false;
            FormUUid.value = "";
            GetTable();
          }
        });
      });
    }

    const Close = function() {
      FormUUid.value = "";
      Model.value = false;
      resetForm();
    };

    return {
      Model,
      SubmitLoading,
      Form: {
        name,
        sort,
        parent,
        seo
      },
      mainCategory,
      subCategory,
      technologyCategory,
      nameError,
      sortError,
      parentError,
      seoError,
      lang,
      Submit,
      FormUUid,
      resetForm,
      Close,
      errors,
    };
  },
  watch: {
    uuid: function(val){
      if(val){
        this.GetDetail();
      }
    },
    mainCategory: function (val) {
      this.Form.parent.value = val;
    },
    subCategory: function (val) {
      if(val){
        this.Form.parent.value = val;
      }else{
        if(this.mainCategory) {
          this.Form.parent.value = this.mainCategory;
        }        
      }
    },
    technologyCategory: function (val) {
      this.Form.parent.value = val;
    }
  },
  methods: {
    GetDetail() {
      Promise.all([
        new Promise(resolve => {
          ApiService.get("/Admin/v1/TechnologyCategory", this.uuid).then(response => {
            if (response.status == 200) {
              this.resetForm();
              for (let k in response.data.data) {
                if(typeof this.Form[k] != "undefined" && response.data.data[k] != null) {
                  switch (k) {
                    case "name":
                      for(let lang in response.data.data.name){
                        this.Form.name.value[lang].name = response.data.data.name[lang].name;
                      }
                    break;
                    case "parent":
                      var parent = response.data.data[k].toString();
                      
                      this.data.map(main => {
                        if(main.uuid == parent) {
                          this.mainCategory = main.uuid;
                        }else{
                          main.sub.map(sub => {
                            if(sub.uuid == parent) {
                              this.mainCategory = main.uuid;
                              this.subCategory = sub.uuid;
                            }
                          });
                        }
                      });
                    break;
                    case "seo":
                      this.Form[k].value = response.data.data[k];
                    break;
                    default:
                      this.Form[k].value = response.data.data[k].toString();
                    break;
                  }
                }
              }
              this.Model = true;
              resolve(response);
            }
          });
        })
      ]);
    }
  }
};
</script>